import { Action } from 'types/index';

export enum SettingsPopulateKeys {
  Calendar = 'calendar',
}

export enum ReservationSettingsActionTypes {
  Loaded = 'Reservation.Loaded',
}

interface BookingLength {
  min: number;
  max: number;
  length: number;
}

export interface ReservationSettingsState {
  phone_required: boolean;
  default_booking_length: number;
  auto_arrival: boolean;
  slot_guests: boolean;
  event_modal: boolean;
  no_show: boolean;
  walk_in: boolean;
  canceled: boolean;
  big_spender_tag: boolean;
  black_list_tag: boolean;
  original_time: boolean;
  interval: number;
}

export interface ReservationSettingsAction extends Action<ReservationSettingsState> {
  type: ReservationSettingsActionTypes;
}
