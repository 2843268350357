import { Action } from ".";

export interface AuthState {
  userSettings: UserSettings;
}

export interface Permissions {
  accessManagerPages: boolean;
  accessUserHasStaffPermissions: boolean;
  accessDashboard: boolean;
  restaurantCanCreateNewRestaurant: boolean;
  accessRestaurantSubManager: boolean;
  accessWebAdmin: boolean;
  accessSettingsPages: boolean;
  accessHubspotChat: boolean;
}

export enum LicenceType {
  freetrial = "freetrial",
  freetrial_reservations = "freetrial_reservations",
  nolicence = "",
}

export interface License {
  type: LicenceType;
  timeLeft?: string;
  reservationsLeft?: string;
}

export interface UserSettings {
  roles: string[];
  permissionToDelete: boolean;
  readOnly: boolean;
  activeLicense: null | License;
  permissions: Permissions;
  uid: number;
}

//Actions
export interface UserSettingsLoadedAction extends Action<{ userSettings: UserSettings }> {type: 'Auth.UserSettingsLoaded'}

export type AuthAction =
  UserSettingsLoadedAction


